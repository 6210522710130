var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseInput",
    _vm._b(
      {
        attrs: {
          type: "email",
          label: "Correo electrónico",
          placeholder: "Correo electrónico",
          value: _vm.value,
          error: _vm.error
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      },
      "BaseInput",
      _vm.$attrs,
      false
    ),
    [
      _c("template", { slot: "left-side" }, [
        _c(
          "svg",
          {
            staticClass: "left-icon",
            attrs: {
              height: "16",
              width: "16",
              fill: "currentColor",
              viewBox: "0 0 20 20"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
              }
            }),
            _c("path", {
              attrs: {
                d: "M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
              }
            })
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }