<template>
  <div class="login container">
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
      <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
        <b-card class="login-form" style="width: 480px;">
          <b-card-body>
            <div class="d-flex align-items-center justify-content-center">
              <Logo :image-url="config.logo" />

              <h1 class="title" v-if="config.name">{{ config.name }}</h1>
              <h1 class="title" v-else>Timelapse <br />México</h1>
            </div>

            <h4 v-if="!esLoginAutomatico" class="text-center mt-4">{{$t('lang.login.msg')}}</h4>

            <div>
              <form @submit.prevent="login">
                <div v-if="!esLoginAutomatico">
                  <div class="mt-3">
                    <EmailInput v-model="form.email" :error="errors.first('email')" />
                  </div>

                  <div class="mt-4">
                    <PasswordInput v-model="form.password" :error="errors.first('password')" />
                  </div>
                </div>
                

                <b-alert class="mt-4" :show="errors.has('login')" variant="danger">{{
                  errors.first('login')
                }}</b-alert>

                <div class="mt-4 text-right" v-if="!esLoginAutomatico">
                  <router-link :to="{ name: 'forgot-password' }">
                    {{$t('lang.reset_password.msg')}}
                  </router-link>
                </div>

                <div class="text-center mt-4" v-if="!errorToken">
                  <button class="btn btn-primary px-5" :disabled="loading">
                    <b-spinner v-if="loading" small></b-spinner>
                    {{$t('lang.login.msg')}}</button>
                </div>
                <div class="text-center mt-4" v-else>
                  <button class="btn btn-primary px-5" :disabled="true">
                    <b-spinner v-if="false" small></b-spinner>
                    Token invalido, cierra la pestaña e intentelo de nuevo.</button>
                </div>
              </form>
            </div>
          </b-card-body>
        </b-card>
      </div>

      <div class="privacy py-4">
        <div class="text-center">Todos los derechos reservados Timelapse México {{anioActual}}</div>
      </div>
    </div>

    <!-- <b-row align-v="center" align-h="center" class="h-100">
      <b-col md="8" lg="6" align-self="center"> </b-col>
    </b-row> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import Errors from '@/util/Errors';
import Logo from '@/components/Logo';
import PasswordInput from '@/components/PasswordInput';
import EmailInput from '@/components/EmailInput';
import axios from 'axios';
export default {
  components: {
    Logo,
    EmailInput,
    PasswordInput,
  },
  data() {
    return {
      esLoginAutomatico: false,
      form: {
        //email: 'urbanhubtl@gmail.com',
        //password: 'urban',
        email: '',
        password: '',
      },
      anioActual: new Date().getFullYear(),
      loading: false,
      errors: new Errors(),
      errorToken: false,
    };
  },
  computed: {
    ...mapState('domain', ['config']),
  },
  created(){
    console.log('ID HUB > ',this.$route.query);
    this.verificarLoginAutomatico();
  },
  methods: {
    async verificarLoginAutomatico(){
      //https://andromaco9.urbanhubtl.site/login?ID=
      //if (window.location.href.indexOf("urbanhubtl") > -1){
      if(true){//verificar que sea el servidor hub? 
        if(this.$route.query.id){//verifico que tenga el parametro de id usuario de hub
          this.loading = true;
          this.esLoginAutomatico = true;
          console.log('ID HUB > ',this.$route.query);
          //const token = this.getTokenHubZoho();
          const usuarioZoho = await this.getDataZoho({
            token: this.$route.query.tk,
            id_user: this.$route.query.id
          });
          console.log("🚀 ~ file: Login.vue:108 ~ verificarLoginAutomatico ~ usuarioZoho", usuarioZoho)
          

          // const usuarioZoho = {
          //   "code": 3000,
          //   "data": [
          //     {
          //       "Nombre": {
          //           "display_value": "UrbanHub Subcontratista",
          //           "prefix": "",
          //           "last_name": "Subcontratista",
          //           "suffix": "",
          //           "first_name": "UrbanHub"
          //       },
          //       "Estatus": "Activo"
          //     }
          //   ]
          // };
          if(usuarioZoho.data){
            if(usuarioZoho.data[0].Estatus){
              //Esta activo y el token es correcto
              console.log('LOGIN automatico activado');
              //ESTO SE DEBE JALAR DEL ENV DE GOOGLE SECRETS
              this.form.email = 'urbanhubzoho@gmail.com';
              this.form.password = 'L6M7hA';
              this.login();

            }else{
              this.loading = false;
              this.errorToken = true;
              //alert('Token no valido, cierra ventana e intenta de nuevo');
            }
          }else{
            this.loading = false;
            this.errorToken = true
          }
        }
      }
    },


    async getTokenHubZoho() {
      try {
        await store.dispatch('auth/getTokenHubZoho');
      } catch (error) {
        console.log("🚀 ~ file: Login.vue:138 ~ getTokenHubZoho ~ error", error)
      }
    },


    async getDataZoho(datos){
      try {
        const resp = await store.dispatch('auth/getDataHubZoho',datos);
        return resp;
      } catch (error) {
        console.log("🚀 ~ file: Login.vue:147 ~ getDataHubZoho ~ error", error)
      }
    },


    login() {
      this.loading = true;
      this.errors.clear();

      store
        .dispatch('auth/login', {
          email: this.form.email,
          password: this.form.password,
        })
        .then(() => {
          this.$router.replace({ name: 'project-list' }).catch(() => {});
        })
        .catch((error) => {
          if (error.response && error.response.data.errors) {
            this.errors.capture(error.response.data.errors);
          }
          this.loading = false;
        });
    },
  },
  mounted() {
    document.body.classList.add('login-page');
  },
  destroyed() {
    document.body.classList.remove('login-page');
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
}

.privacy {
  color: white;
  font-size: 0.875rem;
}

.title {
  text-transform: uppercase;
  font-size: 1.75rem;
  margin-left: 0.5rem;
}
</style>
