var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login container" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          height: "100%",
          display: "flex",
          "flex-direction": "column"
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              flex: "1",
              display: "flex",
              "justify-content": "center",
              "align-items": "center"
            }
          },
          [
            _c(
              "b-card",
              { staticClass: "login-form", staticStyle: { width: "480px" } },
              [
                _c("b-card-body", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center"
                    },
                    [
                      _c("Logo", { attrs: { "image-url": _vm.config.logo } }),
                      _vm.config.name
                        ? _c("h1", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.config.name))
                          ])
                        : _c("h1", { staticClass: "title" }, [
                            _vm._v("Timelapse "),
                            _c("br"),
                            _vm._v("México")
                          ])
                    ],
                    1
                  ),
                  !_vm.esLoginAutomatico
                    ? _c("h4", { staticClass: "text-center mt-4" }, [
                        _vm._v(_vm._s(_vm.$t("lang.login.msg")))
                      ])
                    : _vm._e(),
                  _c("div", [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.login($event)
                          }
                        }
                      },
                      [
                        !_vm.esLoginAutomatico
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "mt-3" },
                                [
                                  _c("EmailInput", {
                                    attrs: { error: _vm.errors.first("email") },
                                    model: {
                                      value: _vm.form.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "email", $$v)
                                      },
                                      expression: "form.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-4" },
                                [
                                  _c("PasswordInput", {
                                    attrs: {
                                      error: _vm.errors.first("password")
                                    },
                                    model: {
                                      value: _vm.form.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "password", $$v)
                                      },
                                      expression: "form.password"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "b-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              show: _vm.errors.has("login"),
                              variant: "danger"
                            }
                          },
                          [_vm._v(_vm._s(_vm.errors.first("login")))]
                        ),
                        !_vm.esLoginAutomatico
                          ? _c(
                              "div",
                              { staticClass: "mt-4 text-right" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: { to: { name: "forgot-password" } }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("lang.reset_password.msg")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.errorToken
                          ? _c("div", { staticClass: "text-center mt-4" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary px-5",
                                  attrs: { disabled: _vm.loading }
                                },
                                [
                                  _vm.loading
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(_vm.$t("lang.login.msg")))
                                ],
                                1
                              )
                            ])
                          : _c("div", { staticClass: "text-center mt-4" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary px-5",
                                  attrs: { disabled: true }
                                },
                                [
                                  false
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _vm._e(),
                                  _vm._v(
                                    " Token invalido, cierra la pestaña e intentelo de nuevo."
                                  )
                                ],
                                1
                              )
                            ])
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "privacy py-4" }, [
          _c("div", { staticClass: "text-center" }, [
            _vm._v(
              "Todos los derechos reservados Timelapse México " +
                _vm._s(_vm.anioActual)
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }